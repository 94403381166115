import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import React from "react";
import {Spec} from "./Spec";

import {ReactComponent as MotorPowerIcon} from '../../assets/MotorPowerIcon.svg'
import {ReactComponent as ChargingTimeIcon} from '../../assets/ChargingTimeIcon.svg'
import {ReactComponent as RangeIcon} from '../../assets/RangeIcon.svg'
import {ReactComponent as MaxSpeedIcon} from '../../assets/MaxSpeedIcon.svg'
import {ReactComponent as DimensionsIcon} from '../../assets/DimensionsIcon.svg'
import {ReactComponent as NetWeightIcon} from '../../assets/NetWeightIcon.svg'
import {ReactComponent as ThermalDeliveryBoxIcon} from '../../assets/ThermalDeliveryBoxIcon.svg';
import MopedPhoto from '../../assets/MopedPhoto.png';
import {ReactComponent as Logo3000XII} from '../../assets/3000XSLogo.svg';
import {Box} from "@mui/system";
import {useSelector} from "react-redux";
import {selectForm} from "../../features/form/formSlice";

export const MopedSpecs = () => {

    const formData = useSelector(selectForm);

    if(formData.countryCode === 'gb') {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <img src={MopedPhoto} alt="Logo" width='100%'/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Container>
                                <Logo3000XII width={320} height={54}/>
                            </Container>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Spec label='MOTOR POWER' value='3000W, peak 5000W' icon={<MotorPowerIcon/>}/>
                                <Spec label='CHARGING TIME' value='Less than 2 hours' icon={<ChargingTimeIcon/>}/>
                                <Spec label='RANGE' value='60 miles' icon={<RangeIcon/>}/>
                                <Spec label='MAX SPEED' value='55mph' icon={<MaxSpeedIcon/>}/>
                                <Spec label='DIMENSIONS' value='2000/670/1180mm' icon={<DimensionsIcon/>}/>
                                <Spec label='NET WEIGHT' value='90kg' icon={<NetWeightIcon/>}/>
                                <Spec label='THERMAL DELIVERY BOX' value='180L' icon={<ThermalDeliveryBoxIcon/>}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    else {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <img src={MopedPhoto} alt="Logo" width='100%'/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Container>
                                <Logo3000XII width={320} height={54}/>
                            </Container>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Spec label='MOTOR POWER' value='3000W, peak 5000W' icon={<MotorPowerIcon/>}/>
                                <Spec label='CHARGING TIME' value='Less than 2 hours' icon={<ChargingTimeIcon/>}/>
                                <Spec label='RANGE (per battery)' value='70 km' icon={<RangeIcon/>}/>
                                <Spec label='MAX SPEED' value='45 km/h / 90 km/h' icon={<MaxSpeedIcon/>}/>
                                <Spec label='DIMENSIONS' value='2000/670/1180mm' icon={<DimensionsIcon/>}/>
                                <Spec label='NET WEIGHT' value='90kg' icon={<NetWeightIcon/>}/>
                                <Spec label='THERMAL DELIVERY BOX' value='180L' icon={<ThermalDeliveryBoxIcon/>}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }


};